import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import QuickstockHeader from "../assets/imgs/quicstock-header.png";
import QuicstockAllInOne from "../assets/imgs/quicstock-all-in-one.png";
import QuicstockDigitization from "../assets/imgs/quicstock-digitization.png";
import QuicstockVisibilityOnTheGo from "../assets/imgs/quicstock-visibility-on-the-go.png";
import QuicstockNeverMissASale from "../assets/imgs/quicstock-never-miss-a-sale.png";
import QuicstockArrowRight from "../assets/svgs/quicstock-arrow-right.svg";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import { ButtonState, RainbowBorderState } from "../components/enum";
import RainbowBorder from "../components/RainbowBorder";
import { JoinWaitingList } from "../components/JoinWaitingList";
import Button from "../components/Button";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getStarted = () => {
    navigate("/auth/login");
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location.hash]);

  return (
    <MainLayout>
      <Helmet>
        <title>Home | Quicstock</title>
        <meta name="keywords" content="New Era" />
      </Helmet>
      <div className={"min-h-screen h-full overflow-x-hidden"}>
        <section className="home-header" id="home">
          <div className="sm:pt-32 pt-24 container">
            <div className="flex flex-col flex-wrap justify-center items-center">
              <div>
                <RainbowBorder
                  state={RainbowBorderState.DARK}
                  text={"Making Stock Control 10x simpler"}
                />
              </div>
              <h1 className="sm:mt-4 mt-16 xl:text-[52px] lg:text-[48px] md:text-[32px] text-[24px] font-medium text-white ">
                Giving You the Power of Big Retail
              </h1>
              <p className="mt-2 md:text-md-headline text-sm-regular font-light text-white">
                Simplify. Streamline. Succeed.
              </p>
              <div className="sm:mt-8 mt-20">
                <JoinWaitingList />
              </div>
              <img
                className="sm:mt-10 mt-24"
                src={QuickstockHeader}
                alt="QuickstockHeader"
              />
            </div>
          </div>
        </section>
        <section className="md:py-16 py-10 container" id="industries">
          <div className="w-full">
            <div className="flex flex-col flex-wrap items-center justify-center">
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-light text-center">
                Special Features on the
              </h4>
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-light text-center">
                <span className="text-primary-500 font-medium">Quic-ker</span>{" "}
                side of life.
              </h4>
              <div className={"mt-8"}>
                <Button
                  css={"!px-16 !py-3 !rounded-[10px]"}
                  state={ButtonState.PRIMARY}
                  text={"Start Quic!"}
                  type={"button"}
                  onClick={getStarted}
                />
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center justify-center mt-16">
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-medium text-center mb-2">
                Your All-in-One Platform
              </h4>
              <p className="lg:text-x-sm-headline text-sm-regular font-light text-center text-gray-500">
                Effortlessly manage inventory with seamless POS and eCommerce
                integration.
                <br />
                Forget the hassle of juggling separate tools.
                <br />
                Enjoy a simplified, streamlined solution.
              </p>
              <div className="w-8/12 mt-10">
                <img
                  src={QuicstockAllInOne}
                  alt="QuicstockAllInOne"
                  className="hover:scale-[1.1] duration-300 transition-all cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center justify-center mt-16">
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-medium text-center mb-2">
                Total Supply Chain Digitalization
              </h4>
              <p className="lg:text-x-sm-headline text-sm-regular font-light text-center text-gray-500">
                Never juggle different tools.
                <br />
                Say YES to a streamlined solution.
                <br />
                Seamless integration of inventory management, POS & e-Commerce
                platforms.
                <br />
                Extra sales user-friendly platform.
              </p>
              <div className="w-5/12 mt-10">
                <img
                  src={QuicstockDigitization}
                  alt="QuicstockDigitization"
                  className="hover:scale-[1.1] duration-300 transition-all cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center justify-center mt-16">
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-medium text-center mb-2">
                Visibility On-the-Go
              </h4>
              <p className="lg:text-x-sm-headline text-sm-regular font-light text-center text-gray-500">
                Maintain seamless and agile operations
                <br />
                Efficient location managment.
                <br />
                Product tracking
              </p>
              <div className="w-5/12 mt-10">
                <img
                  src={QuicstockVisibilityOnTheGo}
                  alt="QuicstockVisibilityOnTheGo"
                  className="hover:scale-[1.1] duration-300 transition-all cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center justify-center mt-16">
              <h4 className="xl:text-[42px] md:text-[36px] text-[24px] font-medium text-center mb-2">
                Never Miss A Sale
              </h4>
              <p className="lg:text-x-sm-headline text-sm-regular font-light text-center text-gray-500">
                Save hours every day on invoice handling.
                <br />
                Verify and submit purchase orders.
                <br />
                Close sales with ease.
                <br />
                Handle returns and refunds instantly
              </p>
              <div className="w-8/12 mt-10">
                <img
                  src={QuicstockNeverMissASale}
                  alt="QuicstockNeverMissASale"
                  className="hover:scale-[1.1] duration-300 transition-all cursor-pointer"
                />
              </div>
            </div>

            <div className="flex flex-col flex-wrap items-center justify-center mt-16">
              <RainbowBorder
                state={RainbowBorderState.LIGHT}
                text={"Get started with us now"}
                css={"!px-10"}
                icon={QuicstockArrowRight}
              />
            </div>
          </div>
        </section>
        <section className="container h-[411px] overflow-hidden">
          <div className="home-base flex justify-center items-center h-full rounded-[20px]">
            <div className="sm:px-0 px-4 flex flex-col flex-wrap justify-center items-center">
              <h1 className="xl:text-[52px] lg:text-[48px] md:text-[32px] text-[22px] font-semibold text-white">
                Simplify. Streamline. Succeed.
                <br />
                Join Quicstock Today.
              </h1>
              <div className="mt-8">
                <JoinWaitingList />
              </div>
            </div>
          </div>
        </section>
      </div>
    </MainLayout>
  );
};

export default Home;
