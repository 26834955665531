// NotificationMessage.tsx
import React, { FC } from "react";
import { ReactComponent as QuicstockNotification } from "../../assets/svgs/quicstock-notification.svg";
import { ReactComponent as QuicstockNotificationError } from "../../assets/svgs/quicstock-notification-error.svg";
import { INotificationMessage } from "../model";

enum NotificationBg {
  success = "bg-link-green",
  error = "bg-error-main",
}

enum NotificationColor {
  success = "text-link-green",
  error = "text-error-main",
}

const NotificationMessage: FC<INotificationMessage> = ({
  type,
  title,
  message,
}) => {
  return (
    <div className="w-full flex gap-x-4 items-center">
      <div
        className={`w-[4px] h-[70px] rounded-[4px] ${NotificationBg[type]}`}
      ></div>
      {type === "success" ? (
        <QuicstockNotification />
      ) : (
        <QuicstockNotificationError />
      )}

      <div>
        <p className={`${NotificationColor[type]} text-x-sm-headline mb-2`}>
          {title}
        </p>
        <p className="text-gray-300 text-sm-regular">
          {type === "error"
            ? message
              ? message
              : "An error occured"
            : message
              ? message
              : "Successful"}
        </p>
      </div>
    </div>
  );
};

export default NotificationMessage;
