import axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

const Http = axios.create({ baseURL });

// let isRefreshing = false
async function refreshToken() {
   let req = localStorage.getItem('dvla_refresh_token');
   await Http.post('/refreshtoken', { refreshToken: req })
      .then((response: any) => {
         if (response.status === 200) {
            localStorage.setItem('dvla_token', response.data.data.accessToken);
            localStorage.setItem('dvla_refresh_token', response.data.data.refreshToken);
         }
      })
      .catch((err: any) => {
         localStorage.clear();
         window.location.href = '/';
         return err;
      });
}

Http.interceptors.response.use(
   (res: any) => res,
   (err: any) => {
      const errObject = err.response;
      if (errObject.status === 401) {
         refreshToken();
      }

      return Promise.reject(err);
   }
);

Http.interceptors.request.use((config: any) => {
   const access_token = localStorage.getItem('dvla_token');
   config.headers.Authorization = `Bearer ${access_token}`;
   return config;
});

export default Http;
