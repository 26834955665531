import { notification } from "antd";
import { ReactNode } from "react";

const openNotification = (message: ReactNode, pauseOnHover: boolean = true) => {
  notification.open({
    message,
    closable: false,
    pauseOnHover,
  });
};

export default openNotification;
