import { ThunkAction } from "redux-thunk";
import { joinWaitlist } from "../_api/index";
import { RootState } from "../reducers";
import { Action } from "redux";
import { JoinWaitlistModel } from "../../model/app.model";

export const Types = {
  JOIN_WAITLIST_START: "JOIN_WAITLIST_START",
  JOIN_WAITLIST_SUCCESS: "JOIN_WAITLIST_SUCCESS",
  JOIN_WAITLIST_END: "JOIN_WAITLIST_END",
};

export const JoinWaitlist = (
  req: JoinWaitlistModel
): ThunkAction<Promise<any>, RootState, unknown, Action> => {
  return async (dispatch) => {
    dispatch({ type: Types.JOIN_WAITLIST_START });
    return new Promise((resolve, reject) => {
      joinWaitlist(req)
        .then(({ data }) => {
          dispatch({ type: Types.JOIN_WAITLIST_SUCCESS });
          resolve(data);
        })
        .catch(({ response }) => {
          dispatch({
            type: Types.JOIN_WAITLIST_END,
            message: response?.data?.message || "An error occured",
          });
          reject(response?.data);
        });
    });
  };
};
