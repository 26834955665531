import React from "react";
import "../styles.scss";
import { Form, Input, Spin } from "antd";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { AppDispatch } from "../../redux/store";
import { JoinWaitlistModel } from "../../model/app.model";
import { JoinWaitlist } from "../../redux/actions/app.action";
import { ApiResponseModel } from "../../model/base.model";
import NotificationMessage from "../Notification";
import openNotification from "../Notification/notification";

const values = {
  email: "",
};

const JoinWaitingList = () => {
  const dispatch: AppDispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.app.isLoading);
  const [form] = Form.useForm();
  const submit = (values: any, { resetForm }: any) => {
    joinWaitlist(values.email, resetForm);
  };

  const joinWaitlist = (email: string, resetForm: any) => {
    const payload: JoinWaitlistModel = { email };
    dispatch(JoinWaitlist(payload))
      .then(async (res: ApiResponseModel) => {
        openNotification(
          <NotificationMessage
            type="success"
            title="Joined waitlist"
            message={res?.message}
          />,
          true
        );
        resetForm();
        form.resetFields();
      })
      .catch((err: any) => {
        openNotification(
          <NotificationMessage
            type="error"
            title="Error"
            message={err?.message}
          />,
          true
        );
      });
  };

  return (
    <Formik initialValues={values} onSubmit={submit} enableReinitialize={true}>
      {({ values, handleChange, handleBlur, handleSubmit }) => (
        <Form onFinish={handleSubmit} form={form}>
          <div className="md:w-[394px] waiting-list">
            <Form.Item name={"email"}>
              <Input
                required
                type={"email"}
                className={"form-waiting"}
                value={values?.email || ""}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email address"
              />
            </Form.Item>
            <button type="submit" disabled={isLoading}>
              Join Waitlist{" "}
              {isLoading ? (
                <span className={"spinner ml-2"}>
                  <Spin size="small" className="btn_loading_spinner" />
                </span>
              ) : (
                ""
              )}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { JoinWaitingList };
