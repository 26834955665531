import { Types } from "../actions/app.action";

interface AppState {
  isLoading: boolean;
  message?: string;
}

interface AppAction {
  type: string;
  message?: string;
}

const INITIAL_STATE: AppState = {
  isLoading: false,
};

export const appReducer = (state = INITIAL_STATE, action: AppAction) => {
  switch (action.type) {
    case Types.JOIN_WAITLIST_START:
      return { ...state, isLoading: true };
    case Types.JOIN_WAITLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.JOIN_WAITLIST_END:
      return { ...state, isLoading: false, message: action.message };
    default:
      return state;
  }
};
